import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NavbarTwo from "./NavbarTwo";
import FooterTwo from "./FooterTwo";

const ComparisonPage = () => {
  const navigate = useNavigate();

  // States for the first filter set
  const [companyFilter, setCompanyFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [lengthFilter, setLengthFilter] = useState("");
  const [priceFilter, setPriceFilter] = useState("");

  // States for the second filter set (comparison filters)
  const [companyFilter2, setCompanyFilter2] = useState("");
  const [typeFilter2, setTypeFilter2] = useState("");
  const [lengthFilter2, setLengthFilter2] = useState("");
  const [priceFilter2, setPriceFilter2] = useState("");

  const [filteredTrucksSet1, setFilteredTrucksSet1] = useState([]);
  const [filteredTrucksSet2, setFilteredTrucksSet2] = useState([]);
  const trucks = JSON.parse(sessionStorage.getItem("filteredTrucks")) || [];

  useEffect(() => {
    let filtered1 = trucks;
    if (companyFilter)
      filtered1 = filtered1.filter((truck) => truck.company === companyFilter);
    if (typeFilter)
      filtered1 = filtered1.filter(
        (truck) => truck.vehicle_name === typeFilter
      );
    if (lengthFilter)
      filtered1 = filtered1.filter((truck) => truck.length === lengthFilter);
    if (priceFilter)
      filtered1 = filtered1.filter((truck) => truck.price === priceFilter);
    setFilteredTrucksSet1(filtered1);

    let filtered2 = trucks;
    if (companyFilter2)
      filtered2 = filtered2.filter((truck) => truck.company === companyFilter2);
    if (typeFilter2)
      filtered2 = filtered2.filter(
        (truck) => truck.vehicle_name === typeFilter2
      );
    if (lengthFilter2)
      filtered2 = filtered2.filter((truck) => truck.length === lengthFilter2);
    if (priceFilter2)
      filtered2 = filtered2.filter((truck) => truck.price === priceFilter2);
    setFilteredTrucksSet2(filtered2);
  }, [
    companyFilter,
    typeFilter,
    lengthFilter,
    priceFilter,
    companyFilter2,
    typeFilter2,
    lengthFilter2,
    priceFilter2,
    trucks,
  ]);

  return (
    <>
    <NavbarTwo/>
      <h1
        style={{ justifyContent: "center", display: "flex", paddingTop: "100px" }}
      >
        Compare Trucks
      </h1>
      <div className="filters-containers2">
        {/* Filters for Set 1 */}
        <div className="filter-bar">
          <div>
            <select
              onChange={(e) => setCompanyFilter(e.target.value)}
              value={companyFilter}
            >
              <option value="">Company</option>
              {Array.from(new Set(trucks.map((truck) => truck.company))).map(
                (company, index) => (
                  <option key={index} value={company}>
                    {company}
                  </option>
                )
              )}
            </select>
            {companyFilter && <p className="placeholder-text">{companyFilter}</p>}
          </div>
          <div>
            <select
              onChange={(e) => setTypeFilter(e.target.value)}
              value={typeFilter}
            >
              <option value="">Truck Type</option>
              {Array.from(
                new Set(trucks.map((truck) => truck.vehicle_name))
              ).map((vehicle_name, index) => (
                <option key={index} value={vehicle_name}>
                  {vehicle_name}
                </option>
              ))}
            </select>
            {typeFilter && <p className="placeholder-text">{typeFilter}</p>}
          </div>
          <div>
            <select
              onChange={(e) => setLengthFilter(e.target.value)}
              value={lengthFilter}
            >
              <option value="">Truck Length</option>
              {Array.from(new Set(trucks.map((truck) => truck.in_length))).map(
                (in_length, index) => (
                  <option key={index} value={in_length}>
                    {in_length}
                  </option>
                )
              )}
            </select>
            {lengthFilter && <p className="placeholder-text">{lengthFilter}</p>}
          </div>
          <div>
            <select
              onChange={(e) => setPriceFilter(e.target.value)}
              value={priceFilter}
            >
              <option value="">Price</option>
              {Array.from(new Set(trucks.map((truck) => truck.price))).map(
                (price, index) => (
                  <option key={index} value={price}>
                    {price}
                  </option>
                )
              )}
            </select>
            {priceFilter && <p className="placeholder-text">{priceFilter}</p>}
          </div>
        </div>

        {/* Filters for Set 2 */}
        <div className="filter-bar">
        <div>
        <select
            onChange={(e) => setCompanyFilter2(e.target.value)}
            value={companyFilter2}
          >
            <option value="">Company</option>
            {Array.from(new Set(trucks.map((truck) => truck.company))).map(
              (company, index) => (
                <option key={index} value={company}>
                  {company}
                </option>
              )
            )}
          </select>
          {companyFilter2 && <p className="placeholder-text">{companyFilter2}</p>}
        </div>
         <div>
         <select
            onChange={(e) => setTypeFilter2(e.target.value)}
            value={typeFilter2}
          >
            <option value="">Truck Type</option>
            {Array.from(new Set(trucks.map((truck) => truck.vehicle_name))).map(
              (vehicle_name, index) => (
                <option key={index} value={vehicle_name}>
                  {vehicle_name}
                </option>
              )
            )}
          </select>
          {typeFilter2 && <p className="placeholder-text">{typeFilter2}</p>}
         </div>
         <div>
         <select
            onChange={(e) => setLengthFilter2(e.target.value)}
            value={lengthFilter2}
          >
            <option value="">Truck Length</option>
            {Array.from(new Set(trucks.map((truck) => truck.in_length))).map(
              (in_length, index) => (
                <option key={index} value={in_length}>
                  {in_length}
                </option>
              )
            )}
          </select>
          {lengthFilter2 && <p className="placeholder-text">{lengthFilter2}</p>}
         </div>
         <div>
         <select
            onChange={(e) => setPriceFilter2(e.target.value)}
            value={priceFilter2}
          >
            <option value="">Price</option>
            {Array.from(new Set(trucks.map((truck) => truck.price))).map(
              (price, index) => (
                <option key={index} value={price}>
                  {price}
                </option>
              )
            )}
          </select>
          {priceFilter2 && <p className="placeholder-text">{priceFilter2}</p>}
         </div>
        </div>
      </div>

      {/* Truck cards container */}
      <div className="truck-container2">
        {/* Trucks List for Set 1 */}
        <div className="truck-list2">
          {filteredTrucksSet1.length > 0 ? (
            filteredTrucksSet1.map((truck, index) => (
              <div key={index} className="truck-card2">
                <h2>{truck.vehicle_name}</h2>
                <img src={truck.href} alt={truck.vehicle_name} />
                <p>
                  <strong>Company:</strong> {truck.company}
                </p>
                <p>
                  <strong>Price:</strong> {truck.price}
                </p>
                <p>{truck.info}</p>
              </div>
            ))
          ) : (
            <p>No trucks available with the selected filters in Set 1.</p>
          )}
        </div>

        {/* Trucks List for Set 2 */}
        <div className="truck-list2">
          {filteredTrucksSet2.length > 0 ? (
            filteredTrucksSet2.map((truck, index) => (
              <div key={index} className="truck-card2">
                <h2>{truck.vehicle_name}</h2>
                <img src={truck.href} alt={truck.vehicle_name} />
                <p>
                  <strong>Company:</strong> {truck.company}
                </p>
                <p>
                  <strong>Price:</strong> {truck.price}
                </p>
                <p>{truck.info}</p>
              </div>
            ))
          ) : (
            <p>No trucks available with the selected filters in Set 2.</p>
          )}
        </div>
      </div>
      <FooterTwo/>
    </>
  );
};

export default ComparisonPage;
