import { BrowserRouter, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import HomeTwo from "./pages/HomeTwo";
import About from "./pages/About";
import Calculate from "./pages/Calculate";
import RouteScrollToTop from "./elements/RouteScrollToTop";
import OnlineEstimatePage from "./pages/OnlineEstimatePage";
import LaborQuote from "./pages/LaborQuote";
import ViewAll from "./components/ViewAll";
import ComparisonPage from "./components/ComparisonPage";
import UserData from "./components/UserData";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <RouteScrollToTop />
      <Routes>
        <Route exact path="/" element={<HomeTwo />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/calculate" element={<Calculate />} />
        <Route exact path="/labor-quote" element = {<LaborQuote />} />
        <Route exact path="/user-data/:uuid" element={<UserData />} />
        <Route
          exact
          path="/truck-rental-page"
          element={<OnlineEstimatePage />}
        />
        <Route exact path="/viewAll" element={<ViewAll />} />
        <Route exact path="/comparison-page" element={<ComparisonPage />} />
      </Routes>
      <ScrollToTop smooth color="#181a2a" />
    </BrowserRouter>
  );
}

export default App;
