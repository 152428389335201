import React from "react";
import { FaRegPaperPlane, FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";

const FooterTwo = () => {
  return (
    <>
      {/* Footer Area Start */}
      <footer className="footer-area">
        {/* Top Section with Company Name & Email Subscription */}
        <div className="footer-top">
          <div className="container">
            <div className="footer-top-content">
              <div className="footer-title">
                <h3>THE DITY TRUCK COMPANY</h3>
                <p>Reserve Trucks and Loads</p>
              </div>
              <div className="subscribe-box">
                <input type="email" placeholder="Your email address" />
                <button>
                  <FaRegPaperPlane />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Middle Section with Links */}
        <div className="container">
          <div className="footer-middle">
            <div className="footer-column">
              <h4>Dity Truck</h4>
              <ul>
                <li><a href="#">Home</a></li>
                <li><a href="#">About us</a></li>
                <li><a href="#">Contact us</a></li>
              </ul>
            </div>

            <div className="footer-column">
              <h4>Quick Links</h4>
              <ul>
                <li><a href="#">Load Help</a></li>
                <li><a href="#">Tutorial</a></li>
              </ul>
            </div>

            <div className="footer-column">
              <h4>Location</h4>
              <ul>
              <li><a>Atlanta, GA 30318</a></li>
              </ul>
             
            </div>

            {/* <div className="footer-column">
              <h4>Customer Service</h4>
              <p>(866) 835-3803</p>
              <p><a href="mailto:reservations@ditytruck.com">reservations@ditytruck.com</a></p>
            </div> */}

            <div className="footer-column">
              <h4>Connect With Us</h4>
              <div className="social-icons">
                <a href="#"><FaFacebookF /></a>
                <a href="#"><FaTwitter /></a>
                <a href="#"><FaInstagram /></a>
                <a href="#"><FaLinkedinIn /></a>
              </div>
            </div>
          </div>
        </div>

        {/* Bottom Section with Copyright */}
        <div className="footer-bottom">
          <p>© Copyright 2022 By DityTruck. All rights reserved.</p>
        </div>
      </footer>
    </>
  );
};

export default FooterTwo;
