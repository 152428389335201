import React, { useState } from "react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SetSearchPopUp } from "../redux/stateSlice/clickActionSlice";
import store from "../redux/store/store";
import { Home } from "@mui/icons-material";
import { Alert } from "@mui/material";
const NavbarTwo = () => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  // useEffect(() => {
  //   window.onscroll = () => {
  //     if (window.pageYOffset < 300) {
  //       setActive(false);
  //     } else if (window.pageYOffset > 300) {
  //       setActive(true);
  //     }
  //     return () => (window.onscroll = null);
  //   };
  // }, []);
  const searchPopUp = useSelector((state) => state.clickAction.searchPopUp);
  const actionSearch = () => {
    store.dispatch(SetSearchPopUp(!searchPopUp));
  };

  const AlertPrompt = ()=>
  {
    alert("Website is under Development");
  }

  // Control sidebar navigation
  let items = document.querySelectorAll(".menu-item-has-children > a");
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector(".sub-menu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }
  return (
    <>
      {/* navbar start */}
      <header className="navbar-area">
        <nav
          className={
            active
              ? "navbar navbar-area-2 navbar-area navbar-expand-lg sticky-active"
              : "navbar navbar-area-2 navbar-area navbar-expand-lg"
          }
        >
          <div
            className={
              !open
                ? "container nav-container"
                : "container nav-container paddingnobottom"
            }
          >
            <div className="responsive-mobile-menu">
              <button
                onClick={() => setOpen(!open)}
                className={
                  open
                    ? "menu toggle-btn d-block d-lg-none open"
                    : "menu toggle-btn d-block d-lg-none "
                }
                data-target="#transpro_main_menu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-left" />
                <span className="icon-right" />
              </button>
            </div>
            <div className="logo">
              <Link className="logo-1" to="/">
                <img
                  src="assets/img/home-2/logoMain.png"
                  alt="Moving and Labor"
                />
              </Link>
              <Link className="logo-2" to="/">
                <img src="assets/img/main-logo-ditytruck.png" alt="logo" />
              </Link>
            </div>
            <div className="rightTextNav"><Link to="/">Home</Link></div>
            <div className="rightTextNav2"><a onClick={() => AlertPrompt()}>Tutorial</a></div>
            <button 
              className="btn btn-base rightButtonNav"
              type="submit"
              style={{ marginBottom: 30, borderRadius: 30, height: 50 }}
              onClick={() => AlertPrompt()}
            >
              Load Help
            </button>
            <div className="nav-right-part nav-right-part-mobile">
            </div>

            <div
              className={
                open
                  ? "collapse navbar-collapse sopen"
                  : "collapse navbar-collapse"
              }
              id="transpro_main_menu"
            >
              <ul className="navbar-nav menu-open text-end">
                <li style={{ paddingLeft: 20 }}>
                  <Link to="/">Home</Link>
                </li>
                <li style={{ paddingLeft: 20 }}>
                  <Link to="/calculate">Calculate</Link>
                </li>
               
              </ul>
            </div>
            <div className="nav-right-part nav-right-part-desktop">
            </div>
          </div>
        </nav>
      </header>
      {/* navbar end */}
    </>
  );
};

export default NavbarTwo;
