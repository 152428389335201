import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const UserData = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [trucks, setTrucks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_USER_DATA}${uuid}`;
        console.log("Fetching from:", apiUrl); // Debugging

        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        // 🔥 Flatten all truck data from different companies into a single array
        const allTrucks = Object.values(data).flat();
        setTrucks(allTrucks);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [uuid]);

  return (
    <div className="d-flex p-5 row">
      <div className="view-all-page">
        <h1>Available Trucks</h1>

        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}

        {!loading && !error && trucks.length > 0 ? (
          <div className="truck-list">
            {trucks.map((truck, index) => (
              <div className="truck-card" key={index}>
                <h2 style={{ fontSize: "30px", color: "#F66711", textAlign: "center" }}>
                  {truck.vehicle_name}
                </h2>
                <img
                  src={truck.vehicle_image}
                  alt={truck.vehicle_name}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "300px",
                    marginBottom: "1rem",
                    borderRadius: "8px",
                  }}
                />
                <p>Company: {truck.company_name}</p>
                <p>Price: {truck.price}</p>
                <p>{truck.info}</p>
              </div>
            ))}
          </div>
        ) : (
          <p>No trucks available.</p>
        )}
      </div>
      <button className="btn btn-base btn-back" onClick={() => navigate("/")}>
        Back
      </button>
    </div>
  );
};

export default UserData;
